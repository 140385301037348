import store from '@/store';
import { getDateNoTime } from '@assets/js/dateUtils';

export const shTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '登记单号', prop: 'segi_no', itemType: 'input', input: false, sortable: false, widthAuto: false },
  {
    label: '收款日期',
    prop: 'segi_date',
    itemType: 'date',
    input: true,
    sortable: true,
    valueFormat: 'timestamp',
    labelWidth: 170,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '收款单情况',
    prop: 'segi_situ',
    itemType: 'select',
    options: [
      { label: '未生成', value: 0 },
      { label: '已生成收款单', value: 1 },
      { label: '已生成预收单', value: 2 }
    ],
    input: true,
    sortable: false,
    widthAuto: false,
    formatter: val => formatSitu(val)
  },
  { label: '汇款客户', prop: 'cust_abbr', itemType: 'input', input: true, sortable: true, labelWidth: 140, overflowTooltip: true },
  { label: '收款金额', prop: 'segi_total', itemType: 'input', input: false, sortable: true, widthAuto: false, align: 'right' },
  {
    label: '汇率',
    prop: 'tran_usdrate',
    itemType: 'input',
    input: false,
    sortable: false,
    widthAuto: false,
    labelWidth: '120px',
    align: 'right'
  },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: true, sortable: false, widthAuto: false, labelWidth: '120px' },
  { label: '本币总额', prop: 'scon_rmbtotal', itemType: 'input', input: false, sortable: true, widthAuto: false, align: 'right' },
  {
    label: '收款方式',
    prop: 'segi_type',
    itemType: 'select',
    options: [
      { label: '电汇', value: 1 },
      { label: '现金', value: 2 },
      { label: '信用证', value: 3 },
      { label: '承兑', value: 4 }
    ],
    input: true,
    sortable: false,
    widthAuto: false,
    formatter: val => formatType(val)
  },
  {
    label: '收款抬头',
    prop: 'cptt_aname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true,
    labelWidth: 140
  },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, widthAuto: false, overflowTooltip: true },
  {
    label: '银行账号',
    prop: 'cptt_bank_account',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true
  },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    itemType: 'date',
    labelWidth: '120px',
    valueFormat: 'timestamp',
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
export const hkTableProperties = [
  store.state.selection,
  store.state.index,
  { label: '登记单号', prop: 'hegi_no', itemType: 'input', input: false, sortable: false, widthAuto: false },
  {
    label: '收款日期',
    prop: 'hegi_date',
    itemType: 'date',
    valueFormat: 'timestamp',
    input: true,
    sortable: true,
    widthAuto: false,
    labelWidth: 160,
    formatter: val => getDateNoTime(val, false)
  },
  {
    label: '收款单情况',
    prop: 'hegi_situ',
    itemType: 'select',
    options: [
      { label: '未生成', value: 0 },
      { label: '已生成收款单', value: 1 },
      { label: '已生成预收单', value: 2 }
    ],
    input: true,
    sortable: false,
    widthAuto: false,
    formatter: val => formatSitu(val)
  },
  {
    label: '汇款客户',
    prop: 'cust_abbr',
    itemType: 'input',
    input: true,
    sortable: true,
    widthAuto: false,
    overflowTooltip: true,
    labelWidth: 160
  },
  { label: '币种', prop: 'cust_currency', itemType: 'input', input: true, sortable: false, widthAuto: false, labelWidth: '120px' },
  {
    label: '公司名称',
    prop: 'cptt_aname',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true,
    labelWidth: 140
  },
  {
    label: '银行账户',
    prop: 'cptt_bank_account',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: false,
    overflowTooltip: true
  },
  {
    label: '收款方式',
    prop: 'hegi_type',
    itemType: 'select',
    options: [
      { label: '电汇', value: 1 },
      { label: '现金', value: 2 },
      { label: '信用证', value: 3 },
      { label: '承兑', value: 4 }
    ],
    input: true,
    sortable: false,
    widthAuto: false,
    formatter: val => formatType(val)
  },
  { label: '收款银行', prop: 'cptt_bank_name', itemType: 'input', input: true, sortable: false, widthAuto: false, overflowTooltip: true },
  { label: '收款金额', prop: 'hegi_total', itemType: 'input', input: false, sortable: true, widthAuto: false, align: 'right' },
  store.state.stff_name,
  {
    prop: 'create_time',
    label: '录入时间',
    input: true,
    valueFormat: 'timestamp',
    itemType: 'date',
    labelWidth: '120px',
    sortable: true,
    formatter: val => getDateNoTime(val, true)
  },
  store.state.status
];
export const remittanceCustomersProperties = [
  { label: '客户编号', prop: 'cust_no', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户简称', prop: 'cust_abbr', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '客户全称', prop: 'cust_name', itemType: 'input', input: true, sortable: false, widthAuto: true },
  { label: '所属国家', prop: 'cust_country', itemType: 'select', options: [], input: true, sortable: false, widthAuto: true },
  { label: '联系方式', prop: 'cust_tel', itemType: 'input', input: false, sortable: false, widthAuto: true },
  {
    label: '出运情况',
    prop: 'tran_count',
    itemType: 'input',
    input: true,
    sortable: false,
    widthAuto: true,
    formatter: val => (val === 0 ? '暂无' : val === 1 ? '存在' : '')
  }
];
const formatType = val => {
  switch (val) {
    case 1:
      return '电汇';
    case 2:
      return '现金';
    case 3:
      return '信用证';
    case 4:
      return '承兑';
  }
};
const formatSitu = val => {
  switch (val) {
    case 0:
      return '未生成';
    case 1:
      return '已生成收款单';
    case 2:
      return '已生成预收单';
  }
};
