import topic from '../topic';
export const hegiAPI = {
  getHegis: topic.hegiTopic + '/get_hegis',
  getHgins: topic.hegiTopic + '/get_hgins',
  getHegiById: topic.hegiTopic + '/get_hegi_by_id',
  addHegi: topic.hegiTopic + '/add_hegi',
  editHegi: topic.hegiTopic + '/edit_hegi',
  deleteHegiByIds: topic.hegiTopic + '/delete_hegi_by_ids',
  deleteHoll: topic.hegiTopic + '/delete_Holl'
};
