<template>
  <div class="importCust">
    <div class="flexV">
      <el-button type="info" size="small" class="vd_export" @click="buttonRefresh()"><i class="el-icon-refresh-right"></i> 刷新</el-button>
    </div>
    <DynamicUTable
      ref="custList"
      v-loading="loading"
      :tableData="tableData"
      :total-page="total"
      :need-search="true"
      :columns="remittanceCustomersProperties"
      @getTableData="getCust"
      @row-dblclick="row => $emit('importCustChange', row)"
    >
      <template v-slot:header-tran_count="row">
        <div style="height: 100%">
          <span style="height: 34px">出运状态</span>
          <div>
            <el-radio @input="changeRadio" v-model="row.tran_count" :label="1">存在</el-radio>
            <el-radio @input="changeRadio" v-model="row.tran_count" :label="0">暂无</el-radio>
          </div>
        </div>
      </template>
    </DynamicUTable>
  </div>
</template>

<script>
import { custAPI } from '@api/modules/cust';
import DynamicUTable from '@/components/table/DynamicUTable.vue';
import { remittanceCustomersProperties } from '@/views/FinanceManagement/SegiManage/segi';
import { getDataCenterByPermId } from '@api/public';

export default {
  name: 'CustList',
  components: {
    DynamicUTable
  },
  data() {
    return {
      total: 0,
      tableData: [],
      loading: false,
      custTypes: [],
      contry: [],
      remittanceCustomersProperties: remittanceCustomersProperties
    };
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      this.remittanceCustomersProperties.find(({ label }) => label === '所属国家').options = await getDataCenterByPermId({
        id: 10003,
        type: 'select'
      });
      await this.getCust();
    },
    async getCust() {
      await (this.loading = true);
      custAPI.getCustByTranForSegi(this.$refs.custList.searchForm).then(({ data }) => {
        this.tableData = data.list;
        this.total = data.total;
        this.loading = false;
      });
    },
    changeRadio(val) {
      this.$refs.custList.searchForm.tran_count = val;
      this.getCust();
    },
    // 刷新
    buttonRefresh() {
      this.$refs.custList.resetFields();
      this.initData();
    }
  }
};
</script>

<style></style>
